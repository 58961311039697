@import "../css/Variable.scss";

@media (max-width: 750px) {
  // *************viliyan**********************

  .signup-pwd-top {
    top: 68% !important;
  }

  .Sign-reenter-top {
    top: 57% !important;
  }
  .SendOTPButton {
    width: 37%;
  }
  .Submenu-color-home {
    font-size: 14px;
  }

  .frame-yt-video {
    display: inline-block !important;
  }

  .footer-down-social-menu {
    display: block !important;
  }

  .cartpage-heading-theme3 {
    margin-top: 10px;
    font-size: 18px;
  }

  .footer-right-links {
    width: 100% !important;
  }

  .footer-left-links {
    width: 100% !important;
  }

  .footer-left-title {
    margin: 20px 20px 0px 20px !important;
  }

  .footer-social-links-container {
    margin-bottom: 95px !important;
  }

  .footer-app-p-graph {
    display: none;
  }

  // ***********************************

  .nav-bar-mobile {
    display: flex !important;
    flex-direction: row-reverse;
    justify-content: initial;
  }

  .nav-space {
    display: none;
  }

  .navbar {
    display: none;
  }

  .ProductDetailPage {
    flex-direction: column;
  }

  .ProductDetailPage-left {
    width: 100%;
    flex-direction: column-reverse;
  }

  .ProductDetailPage-left-c2 {
    width: 100%;
    flex-direction: column-reverse;
  }

  .ProductDetailPage-imagebox2 {
    width: 100%;
    margin: auto;
    outline: none;
    min-height: 160px;
    padding-top: 0;
    margin-top: 10px;
    border: 1px solid rgb(211, 211, 211);
    padding: 20px 0px;
  }

  .ProductDetailPage-imagebox1 {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 0;
  }

  .ProductDetailPage-right {
    width: 100%;
  }

  .ProductDetailPage-right-c2 {
    width: 100%;
  }

  .ProductDetailPage-imagebox2-c2 {
    min-height: 180px;
  }

  .productdetailbox {
    margin-top: 5px;
  }

  .ProductDetailPage-smallimg-c2 {
    height: 60px;
  }

  .ProductDetailPage-smallimg {
    width: 22%;
  }

  .product-detail-button-box {
    width: 100%;
    margin: auto;
    position: fixed;
    z-index: 10;
    bottom: 60px;
    left: 0;
    background: white;
  }

  .product-detail-button {
    width: 50%;
    padding: 0;
    height: 50px;
    border-radius: 0;
    // outline: 1px solid white;
  }

  .productdetail-name {
    font-size: 17px;
    line-height: 18px;
  }

  .color-section-pd p {
    margin-top: 8px;
  }

  .productdetail-bottom-box {
    margin-top: 5px;
  }

  .card-product-name {
    font-size: 13px;
  }

  .product-rating-box {
    width: 100px;
    padding: 2px;
  }

  .card-product-price {
    font-size: 13px;
    margin-top: 0px;
  }

  .card-product-price-2 {
    margin-top: 0px;
    font-size: 12px;
  }

  .card-product-desc {
    font-size: 12px;
    margin-top: 1px;
  }

  .outline-coloroption {
    margin: 10px 25px 10px 0px;
  }

  .ProductSearch {
    display: flex;
    flex-direction: column;
  }

  .ProductSearch-left {
    display: none;
    width: 100%;
  }

  .ProductSearch-right {
    width: 100%;
  }

  .card-product-image-1 {
    height: 180px;
  }

  .card-product {
    width: 90%;
    padding-bottom: 10px;
    margin: 3px 10px;
  }

  .cart-product-for-two-row {
    width: 48% !important;
    margin: 5px 0px;
    min-height: 180px;

    .productswap1 {
      width: 140px !important;
      height: 80px;
    }

    .productswap2 {
      width: 150px !important;
      height: 80px;
    }

    .productswap3 {
      width: 140px !important;
      height: 80px;
    }

    .card-product-image-1 {
      height: 125px;
    }

    .card-product-details-1 {
      text-align: left;
      margin-left: 13px;
      margin-right: 5px;
    }

    .product-rating-box {
      bottom: 2px;
    }

    .card-product-name {
      font-size: 12px;
      line-height: 15px;
    }

    .card-product-price {
      font-size: 12px;
      line-height: 12px;
      margin-top: 7px;
    }

    .card-product-price-2 {
      font-size: 11px;
      line-height: 12px;
      margin-top: 7px;
    }

    .card-product-desc {
      font-size: 10px;
      margin-top: 5px;
      line-height: 13px;
    }

    .product-rating-box {
      width: 80px;
      padding: 1px;
      left: 15px;
      display: none;
    }

    .card-menu-favorite {
      position: absolute;
      top: 7px;
      font-size: 16px;
      right: 9px;
      z-index: 1;
      border: 0;
    }

    .Card-product-type-1-top {
      min-height: 90px;
    }

    .Card-product-type-1-rating-color-box {
      display: flex;
      justify-content: space-between;
      width: 87%;
      flex-direction: column;
      align-items: center;
      margin-bottom: 3px;
    }

    .Card-product-type-1-end {
      height: 20px;
      background: linear-gradient(to right, rgb(1, 141, 160), rgb(122 180 187));
      margin-top: 5px;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      font-weight: 100;
      font-size: 9px;
      display: flex;
      align-items: center;
      padding-left: 15px;
      color: rgb(255, 255, 255);
    }

    .outline-coloroption-c2 {
      width: 13px;
      height: 13px;
      border: 1px solid rgb(196, 196, 196);
      border-radius: 50%;
      padding: 2px;
      margin-right: 3px;
    }

    .color-option-box1-c2 {
      background-color: rgb(0, 0, 0);
    }

    .color-option-box2-c2 {
      background-color: rgb(175, 175, 175);
    }

    .color-option-box3-c2 {
      background-color: rgb(0, 179, 255);
    }

    .color-option-box4-c2 {
      background-color: rgb(85, 85, 85);
    }

    .color-option-box5-c2 {
      background-color: rgb(182, 8, 8);
    }

    .color-option-box-main {
      width: 13px;
      height: 13px;
      border-radius: 50%;
    }

    .Card-product-type-1-rating-box {
      width: 85px;
      height: 11px;
      margin-bottom: 4px;
    }

    .productcart-bottom-txt-box {
      padding: 10px;
      padding-bottom: 0px;
      padding-top: 5px;
    }

    .product-cart-3-txt-3 {
      font-size: 11px;
      margin-right: 3px !important;
    }

    .product-cart-3-txt-4 {
      font-size: 9px;
      margin-right: 6px !important;
    }

    .product-cart-3-txt-5 {
      font-size: 10px;
    }

    .product-cart-3-txt-6 {
      font-size: 10px;
    }

    .product-cart-3-txt-7 {
      font-size: 9px;
    }

    .Card-product-type-1-end1 {
      font-size: 9px;
      height: 23px;
      padding-left: 10px;
    }

    .card-4-text-box-1 p {
      margin: 0;
    }

    .card-product-details-2 {
      text-align: left;
      margin-left: 10px;
      margin-right: 10px;
    }

    .card-4-text-box-1-txt-1 {
      color: rgb(85, 85, 85);
      font-size: 13px;
    }

    .card-4-text-box-1-txt-2 {
      color: rgb(85, 85, 85);
      font-size: 12px;
      font-weight: 100;
      line-height: 13px;
    }

    .card-4-text-box-1-txt-3 {
      color: rgb(0, 0, 65);
      font-size: 12px;
      margin-top: 3px !important;
    }

    .slick-slide {
      display: flex !important;
      justify-content: center;
      margin-top: 10px;
      padding-bottom: 15px;
    }
  }

  .ProductDetailPage-imagebox2-c2 {
    width: 90%;
  }

  .productdetailbox {
    min-height: 250px;
  }

  .ProductSearch-top {
    justify-content: space-between;
    padding-left: 20px;
    height: 55px;
    top: 60px;
  }

  .product-grid-btn {
    width: 18px;
    padding: 10px;
    display: block;
    padding-top: 8px;
  }

  .filter-heading {
    height: 35px;
  }

  .ProductSearch-section {
    padding-top: 10px;
  }

  .filter-select {
    width: 145px;
    right: 50px;
  }

  .cartpage {
    flex-direction: column;
  }

  .cartpage-left {
    width: 100%;
    padding: 15px 0px 0px 0px;
  }

  .cartpage-right {
    width: 100%;
  }

  .cartpage-heading {
    margin: auto !important;
    margin-bottom: 10px !important;
  }

  .cartpage-pricebox {
    margin: auto !important;
    margin-top: 5px !important;
    margin-bottom: 10px !important;
    width: 90%;
    padding: 5px;
    height: max-content;
    min-height: 10px;
  }

  .pricebox-type {
    padding: 10px;
  }

  .pricebox-heading {
    font-size: 13px;
  }

  .pricebox-heading-2 {
    font-size: 13px;
  }

  .pricebox-coupon {
    font-size: 13px;
    display: flex;
    align-items: center;
  }

  .pricebox-1 img {
    width: 18px;
    height: 18px;
  }

  .pricebox-1 i {
    font-size: 15px;
  }

  .review-box {
    width: 86%;
    padding: 5px 10px 15px 10px;
    height: max-content;
  }

  .mobile-nav-spacing {
    display: block;
  }

  .review-box-detail-line-1 {
    height: max-content;
    display: flex;
    margin: 0;
    margin-bottom: 5px;
    font-size: 15px;
  }

  .review-box-detail-line-2 {
    height: 15px;
  }

  .review-box-detail-line-3 {
    height: 20px;
  }

  .review-box-detail-line-4 {
    height: 20px;
    margin-top: 3px;
  }

  .review-box-name {
    font-size: 11px;
    line-height: 15px;
  }

  .review-price {
    margin: 0;
    font-size: 11px;
    font-weight: 100;
    color: #9a9a9a;
    line-height: 25px;
  }

  .review-price-2 {
    margin: 0;
    font-size: 11px;
  }

  .fa-trash {
    color: rgb(230, 0, 0);
    cursor: pointer;
    font-size: 15px;
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-right: 15px;
    transition: all;
    transition-duration: 300ms;
  }

  .fa-trash:hover {
    scale: 1.2;
  }

  .quantity {
    margin-top: 2px;
  }

  .frame-txt {
    font-size: 11px;
    color: $Theme-color;
    display: flex;
    // font-weight: 600;
  }

  .lens-txt {
    font-size: 11px;
    color: $Theme-color;
    display: flex;
    // font-weight: 600;
  }

  .review-box-detail-line-3 div {
    justify-content: center;
  }

  .review-box-detail-line-2 div {
    justify-content: center;
  }

  .review-product-name {
    font-size: 10px;
    color: rgb(143 143 143);
    margin-left: 10px;
    font-weight: 100;
  }

  .footer-top {
    flex-direction: column-reverse !important;
  }

  .footer {
    height: max-content !important;
  }

  .footer-top-part {
    width: 100% !important;
  }

  .media-links {
    padding: 15px 0px 0px 0px !important;
  }

  .footer {
    padding-bottom: 65px;
  }

  .footer-bottom {
    width: 80% !important;
    margin: auto !important;
    margin-bottom: 10px !important;
    font-size: 13px !important;
    margin-top: 10px !important;
  }

  .footer-text-header {
    margin-left: 35px;
    margin-right: 35px;
  }

  .footer-text-link {
    font-size: 13px !important;
    line-height: 16px;
  }

  .ProductDetailPage-bottom {
    margin-bottom: 0;
  }

  .button-container-bottom {
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 10;
    border-radius: 0;
    box-shadow: none;
    border-top: 1px solid rgb(236, 236, 236);
  }

  .ProductDetailPage-bottom-1 {
    width: 95%;
    padding: 0px 10px;
  }

  .buywithlens-content {
    flex-direction: column;
  }

  .buywithlens-left {
    width: 100%;
  }

  .buywithlens-right {
    width: 100%;
  }

  .buywithlens {
    margin: 0;
    width: 100%;
  }

  .buywithlens-main {
    padding-top: 0;
  }

  .buywithlens-header-section {
    width: 100%;
  }

  .buywithlens-header-txt {
    width: 50%;
    font-size: 13px;
    padding: 0;
  }

  .lens-option-top p {
    font-size: 14px;
  }

  .lens-option-pararaph {
    font-size: 12px;
  }

  .buy-with-lens-option {
    padding: 15px 5px 15px 37px;
  }

  .zeropower-txt {
    font-size: 14px;
    text-align: center;
    padding-top: 00px;
    padding-bottom: 0px;
  }

  .review-box-image {
    width: 22%;
  }

  .review-box-detail {
    width: 75%;
  }

  .Description-accordian-box p {
    margin: 10px;
  }

  .Features-accordian-box table {
    width: 100%;
  }

  .Features-accordian-box {
    margin: 0px;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .Features-accordian-box table tr td {
    font-size: 12px;
  }

  .Specification-accordian-box p {
    font-size: 13px;
    margin: 5px 5px;
  }

  .prescriptionOptiontable {
    margin: 0;
    width: 310vw;
  }

  .buywithlens-content {
    flex-direction: column;
    width: 100vw;
    overflow-y: scroll;
  }

  td {
    border: 1px solid rgb(207, 207, 207);
    padding: 8px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
  }

  .table-head h5 {
    margin: 0;
    font-size: 12px;
    margin-top: 0px;
    color: #6d6d6d;
    font-weight: 100;
    height: 35px;
  }

  .table-head a {
    padding: 7px;
  }

  .admin-page-content {
    flex-direction: column;
    background: whitesmoke;
  }

  .admin-page-left {
    width: 100%;
    height: max-content;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .admin-page-right {
    width: 100%;
    height: max-content;
  }

  .personal-info-text-box {
    width: 92%;
  }

  .input2 {
    width: 210px;
  }

  .fs-13 {
    font-size: 14px;
  }

  .admin-my-profile {
    margin-bottom: 15px;
  }

  .admin-right-section {
    width: 93%;
    margin: auto;
    background-color: white;
    height: 95%;
    margin-bottom: 15px;
    padding: 5px;
  }

  .admin-options {
    width: 75%;
    padding: 15px 30px;
    border-radius: 50px;
    margin: auto;
    background: white;
    margin-bottom: 10px;
  }

  .title {
    width: 95%;
    margin-bottom: 0;
    justify-content: flex-start;
  }

  .image-slider {
    height: 210px;
  }

  .product-slider-1 {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 80%;
  }

  .productcard {
    width: 70px !important;
    height: 100px;
  }

  .section-1 {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .section-1-left {
    width: 100%;
    padding: 0;
    height: 55vw;
  }

  .section-1-right {
    width: 95%;
  }

  .section-2 {
    height: 160px;
  }

  .productcard2 {
    width: 60vw !important;
    height: 30vw;
  }

  .section-3 {
    width: 100%;
    flex-direction: column;
    height: max-content;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .section-1-right-button {
    padding: 0;
    justify-content: center;
    margin-top: 20px;
  }

  .productcard3 {
    width: 40vw;
    height: 20vw;
  }

  .section-5 {
    flex-direction: column;
    height: max-content;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 10px;
  }

  .product-slider-heading {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
    padding-bottom: 0px;
    margin-bottom: 5px;
  }

  .section-5-card {
    width: 90%;
    margin-bottom: 15px;
    margin-top: 0px;
    border-radius: 15px;
  }

  .productcard4 {
    height: 13vw;
    padding: 0;
  }

  // .product-slider-2 {
  //   margin-bottom: 30px;
  //   margin-top: 30px;
  // }

  .section-1-right-head {
    font-size: 23px;
    margin-bottom: 0;
  }

  .slick-slider {
    width: 87%;
    padding: 0px 0px 10px 0px;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px !important;
  }

  .section-1-right-data {
    font-size: 14px;
    padding: 0px 10px;
    margin-top: 5px;
  }

  .btn-53 {
    padding: 0.5rem 2rem;
  }

  .form-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
  }

  .form-container-1 {
    width: 90%;
    position: fixed;
    top: 66%;
    left: 50%;
    transform: translate(-50%, -70%);
  }

  .my-order-table {
    height: 158px;
  }

  .mbl-cart-pg {
    position: absolute;
    background: #15bec9;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    right: -14px;
    top: -8px;
    padding: 2px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mbl-cart-pg2 {
    position: absolute;
    background: #0ec2ce;
    height: 12px;
    width: 12px;
    border-radius: 54px;
    right: -12px;
    color: white;
    font-size: 10px;
    bottom: 16px;
    justify-content: center;
    display: flex;
    padding: 2px 3px 3px 4px;
    align-items: center;
  }

  .mbl-top-rel {
    position: relative;
  }

  .order-detail-list {
    height: 155px;
  }

  .form-container-login-theme3 {
    top: 58%;
  }

  .main-modal-mothers-hut {
    width: 300px;
    top: 43%;
    left: 50%;
  }

  .favourite-box {
    width: 90%;
    height: auto;
    padding: 5px 0px;
    margin-top: 0;
  }

  .favourite-box-container {
    height: 460px;
    padding-top: 10px;
  }

  .image-slider-button {
    font-size: 12px;
    width: 25px;
    height: 25px;
    padding: 5px;
    margin: 10px;
  }

  .image-slider-button-box {
    top: 38%;
  }

  .button-2 {
    padding-block: 10px;
    padding-inline: 50px;
    margin-top: 15px;
  }

  .mobile-back-btn {
    font-size: 19px;
  }

  .pricebox-type-btn {
    position: fixed;
    bottom: 61px;
    z-index: 1;
    left: 0;
    border-radius: 0;
    height: 55px;
  }

  .filter-mobile-btn {
    background-color: white;
    color: black;
    display: block;
    position: fixed;
    bottom: 58px;
    z-index: 2;
    left: 0;
    border-radius: 0;
    height: 55px;
  }

  .cartpage-pricebox:hover {
    scale: none;
  }

  .mobile-cart-price-box {
    display: flex;
    position: fixed;
    bottom: 116px;
    border-top: 1px solid #e1e1e1;
    // border-bottom: 1px solid black;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background-color: white;
    z-index: 1;
  }

  .mobile-cart-price-box p {
    margin-left: 20px;
    font-size: 15px;
    margin-right: 20px;
    font-weight: 600;
  }

  .continue-button-bwl {
    position: fixed;
    bottom: 62px;
    // border-bottom: 1px solid black;
    justify-content: space-between;
    width: 100%;
    left: 0;
    height: 50px;
    border-radius: 0;
    z-index: 1;
  }

  .poductcard4-head {
    margin: 0;
    text-align: center;
    font-size: 9px;
    line-height: 15px;
    font-weight: 100;
    margin: 0 3px;
  }

  .poductcard4-txt {
    margin: 0 5px;
    text-align: center;
    font-size: 9px;
    line-height: 12px;
  }

  .prductcard-4-box {
    padding: 15px 0;
  }

  .search-panal-mobile {
    width: 100%;
  }

  .product-cartmain-2 {
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .product-cartmain-2 p {
    font-size: 22px;
    margin: 0;
  }

  .product-cartmain-2 h4 {
    font-size: 17px;
    margin: 0;
    font-weight: 100;
    color: #666666;
  }

  .product-cartmain-3 {
    text-align: center;
  }

  .product-cartmain-3 p {
    font-size: 10px;
    margin: 0;
  }

  .product-cartmain-3 h4 {
    font-size: 11px;
    margin: 0;
    font-weight: 100;
    color: #666666;
  }

  .mobile-filter-page {
    display: block;
  }

  .card-product:hover {
    border: 1px solid rgb(104, 104, 104);
    box-shadow: 12px 17px 51px rgba(49, 49, 49, 0.22);
    transform: scale(1);
  }

  .section-1-right-small {
    padding: 10px;
  }

  .slick-dots li button:before {
    font-size: 7px !important;
  }

  .adress-delete-edit {
    position: absolute;
    top: 20%;
    right: 10px;
    font-size: 20px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    cursor: pointer;
  }

  .filter-line {
    display: flex;
    font-size: 15px;
    margin: 0px;
    align-items: center;
    border-bottom: 1px solid #c3c3c3;
    padding: 10px 0px;
  }

  .Card-product-type-1 {
    width: 95%;
    margin: 10px auto;
  }

  .banner-1-style {
    width: 100%;
    padding: 0px 0px;
    margin-top: 2px;
    // height: 150px;
    background-color: #f3f3f3;
  }

  .section-6-img-box {
    width: 24vw;
    margin: 5px;
    height: 20vw;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
  }

  .review-box-options {
    font-size: 10px;
    padding: 4px;
    margin-right: 5px;
  }

  .review-box-2 {
    width: 88%;
    padding: 0px 10px;
    height: 130px;
    margin-top: 0;
  }

  .cartpage-2-product-remove {
    bottom: 7px;
    right: 26px;
    font-size: 15px;
  }

  .quantity__minus,
  .quantity__plus {
    display: block;
    width: 18px;
    height: 18px;
    margin: 0;
    background: #9e9e9e;
    text-decoration: none;
    text-align: center;
    line-height: 19px;
    font-size: 18px;
  }

  .quantity__input {
    font-size: 12px;
    height: 14px;
  }

  .quantity {
    margin-top: 10px;
    justify-content: flex-start;
    padding: 0 !important;
  }

  .pricebox-type-1 {
    padding: 10px;
  }

  .product-name-3 {
    font-size: 16px;
    margin: 0px 20px;
    margin-top: 10px;
    color: rgb(66, 66, 66);
  }

  .ProductDetailPage3-section h2 {
    font-size: 18px;
  }

  .input-group {
    margin-left: 20px;
  }

  .input3:focus,
  .input3:valid {
    outline: none;
    border: 1.5px solid #c9c9c9;
  }

  .page-detail-review-section {
    height: 100%;
    flex-direction: column;
    margin-top: 15px;
  }

  .page-detail-review-section-start {
    width: 100%;
    padding: 20px 0px;
  }

  .page-detail-review-section-left {
    width: 100%;
  }

  .page-detail-review-section-right {
    width: 100%;
    margin-top: 20px;
    padding-top: 10px;
    height: 610px;
  }

  .page-detail-review-section-start-main {
    width: 83%;
    height: 490px;
  }

  .Specification-accordian-box {
    padding: 0;
  }

  .page-detail-review-section-left-main {
    width: 93%;
    height: 100%;
  }

  .input-group div {
    right: 20px;
  }

  .input3 {
    width: 90%;
  }

  .page-detail-review-section-right-main {
    width: 84%;
  }

  .rating-input-box {
    height: 60px;
  }

  .productcart-bottom-txt-box {
    padding: 15px;
    padding-bottom: 0px;
    padding-top: 5px;
  }

  .product-cart-3-txt-1 {
    font-size: 13px;
  }

  .product-cart-3-txt-2 {
    font-size: 11px;
    line-height: 12px;
  }

  .product-cart-3-txt-3 {
    font-size: 14px;
    margin-right: 5px !important;
  }

  .product-cart-3-txt-4 {
    font-size: 10px;
    margin-right: 10px !important;
  }

  .product-cart-3-txt-5 {
    font-size: 12px;
  }

  .product-cart-3-txt-6 {
    font-size: 12px;
  }

  .product-cart-3-txt-7 {
    font-size: 11px;
  }

  .Card-product-type-1-end1 {
    font-size: 11px;
    height: 40px;
    padding-left: 0px;
  }

  .slick-slide {
    display: flex !important;
    justify-content: center;
    margin-top: 10px;
    padding-bottom: 10px;
  }

  .Card-product-type-1-end {
    padding-left: 20px;
  }

  .Card-product-type-1-end1 {
    margin-top: 5px;
  }

  .slick-dots {
    bottom: -5px !important;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 17px !important;
  }

  .similer-product-section-1 {
    width: 98%;
    padding: 5px;
  }

  // ********************************** new css himasnhu******************************

  .productSearch-section-wrap3 {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    gap: 0;
    justify-content: center;
  }

  .card-product-main-container11 {
    position: relative;
    width: 100vw;
  }

  .Card-product-type-11 {
    width: 93%;
    height: auto;
    margin: 10px auto;
  }

  .productSearch-section-wrap3::-webkit-scrollbar {
    display: none;
  }

  .ProductDetailPage-smallimg-theme3 {
    width: 90%;
    // height: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    min-height: max-content;
    margin-top: 5px;
    outline: 1px solid rgb(211, 211, 211);
  }

  .ProductDetailPage-imagebox2 img {
    width: 100%;
    margin-bottom: 10px;
  }

  .ProductDetailPage-left-theme3 {
    width: 96%;
    height: 100%;
    display: flex;
    margin-left: 0px;
    margin: auto;
  }

  .wish-side-ihdds2 {
    width: 90%;
    margin-top: 10px;
  }

  .OrderOnPhone-line_Lc {
    display: none;
  }

  .product-detail-return {
    margin-bottom: 30px;
  }

  .Flex-Technical-Arrow {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    border-bottom: $border-light;
  }

  .cartpage-high-theme3 {
    flex-direction: column;
  }

  .cartpage-left-theme3 {
    // display: flex;
    width: 100%;
    // flex-direction: column;
  }

  .link-youtube-frame {
    flex-direction: column;
  }

  .heading-panel-1-theme3 {
    width: 75%;
    font-size: 14px;
    font-weight: 500;
    color: #5d5858;
  }

  .review-box-options--theme3 {
    padding-bottom: 8px;
    padding-top: 5px;
    padding-left: 5px;
    margin-right: 5px;
    margin-top: 3px;
    padding-right: 5px;
    font-size: 12px;
  }

  .cartpage-right-theme3 {
    width: 100%;
  }

  .pricebox-type-btn-theme3 {
    width: 85%;
    height: 37px;
    margin: auto;
    margin-top: 10px;
    font-size: 16px;
  }

  .cart-container-scroll {
    height: max-content;
  }

  .wish-side-ihdds {
    width: 90%;
    margin: auto;
  }

  .navbar-option {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .cartpage-theme4 {
    min-height: 30vh;
    width: 100%;
    background-color: #f7f7f7;
    padding: 0 0%;
    justify-content: center;
  }

  .mobile-lowerbar-txt {
    margin: 0;
    margin-top: 5px;
  }

  .navbar_bottom_theme_3 {
    display: none;
  }

  .cards {
    display: none;
  }

  .Flex-WearTrend_Lc {
    flex-direction: column;
    margin-top: 20px;
  }

  .WearThe_theme3_Lc {
    width: 90%;
    font-size: 17px;
    font-weight: 500;
    text-align: left;
    letter-spacing: -1px;
  }

  .LeftArrow_Lc {
    width: 40px;
    text-indent: -9999px;
    font-size: 26px;
    padding-top: 15%;
    cursor: pointer;
    height: 40px;
    left: 0;
    top: 0;
  }

  .Right-Arrow_Lc {
    width: 40px;
    text-indent: -9999px;
    font-size: 26px;
    padding-top: 15%;
    cursor: pointer;
    height: 40px;
    right: 0;
    top: 0;
  }

  .ExploreImg-width {
    width: 64px;
    height: 110px;
  }

  .Trend-Title_Lc {
    margin: 0;
    font-size: 12px;
  }

  .Trend-Explore_Lc {
    background: #56b7c3;
    color: #ffffff;
    padding: 4px 0;
    width: 90px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    border-radius: 5px;
    margin-top: 4px;
    letter-spacing: 0.5px;
  }

  .Flex_Slide1_Lc {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: auto;
    margin-bottom: 5px;
  }

  .ProductImg-Margin_Lc {
    margin: 0px 0px;
    width: 30%;
  }

  .Img-Trend_Lc {
    // background-size: 80%;
    // background-position: center;
    width: 265px;
    // height: 200px;
    // background-repeat: no-repeat;
    // margin: auto;
  }

  .Flex-ProductSlider_Lc {
    width: 90%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 5px;
    border: 0;
  }

  .NextSlide_Lc {
    width: 28px;
    height: 28px;
    margin-top: 25px;
    right: -7px;
    top: 50px;
  }

  .frame-yt-video {
    width: 100%;
    height: 310px;
  }

  .theme3-navbar-onhover {
    display: none;
  }

  .Category-banner-img {
    width: 100%;
    height: max-content !important;
  }

  .Title-Name_Lc {
    letter-spacing: 0.5px;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    color: black;
  }

  .Right-Arrow-Lc {
    font-size: 20px;
    right: 15px;
  }

  .Left-Arrow-Lc {
    font-size: 20px;
    left: 15px;
  }

  .werethetrendtxt {
    display: flex;
  }

  .werethetrendtxt div {
    margin-left: 5px;
  }

  .ProductEyeglass-Font_Lc {
    font-size: 15px;
  }

  .ViewRange-Font_Lc {
    font-size: 14px;
  }

  .review-box-image img {
    width: 90%;
    padding: 20px 0px;
  }

  .PrevSlide_Lc {
    width: 28px;
    height: 29px;
    margin-top: 25px;
    left: -7px;
    top: 50px;
  }

  .Banner-Slider_Lc {
    height: 140px;
  }

  .cartpage-pricebox-container-theme3-pt {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    justify-items: center;
  }

  .product-detail-button-box-theme3 {
    margin: 10px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ProductDetailPage-imagebox2 {
    border: 0;
    padding: 0;
  }

  .navbar_theme_3 {
    display: none;
  }

  // ********************************** new css himasnhu****************************

  //*******************************Lenskart css Chaitan**************************************
  .cartpage-high-theme3 {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0px;
    padding: 0;
  }

  .cartpage-left-theme3 {
    width: 100% !important;
  }

  .Flex-Checkout-powers {
    width: 90% !important;
  }

  .Address-font-shipping {
    width: 60% !important;
  }

  .Border-Saved-Address-Lc {
    width: 90% !important;
    height: max-content;
  }

  .Flex-Add-New-Lc {
    width: 94% !important;
  }

  .cartpage-right-theme3 {
    width: 100% !important;
  }

  .flex-Shipping-address {
    width: 100% !important;
    gap: 2px !important;
  }

  .icon-margin-Rarrow {
    margin: 5px;
    letter-spacing: 0.15px;
  }

  .Top_font-Shipping-Add {
    letter-spacing: 0px !important;
  }

  .Save-Address-theme3 {
    width: 96% !important;
  }

  .New-Address-shipp {
    font-size: 17px !important;
  }

  .Saved-Add-shipp {
    font-size: 20px !important;
  }

  .Flex-input-shipping {
    flex-direction: column;
    width: 100% !important;
    padding: 0px !important;
  }

  .Flex-Input-Address-Lc {
    width: 98% !important;
    padding: 0px !important;
  }

  .Home-Icon-Box-Lc {
    width: 28% !important;
    margin-right: 8px !important;
  }

  .Flex-Home-Work-Other {
    justify-content: center !important;
    margin-bottom: 10px !important;
  }

  .shipping-Address-flex {
    display: 0px !important;
  }

  .Input-Box-shipping-Lc {
    width: 94% !important;
  }

  .addaddress_lable {
    font-size: 15px !important;
  }

  .Flex-Apply-Coupon {
    width: 88% !important;
  }

  .theme3-navbar-onhover {
    display: none !important;
  }

  .navbar_Bottom_left_theme_3 {
    display: none !important;
  }

  .flex-UPI-Pay {
    width: 80% !important;
  }

  .favourite-box-admin {
    width: 98% !important;
  }

  .favourite-img {
    padding: 2px !important;
  }

  .review-box-detail2 {
    width: 60% !important;
  }

  .customer-title-1 {
    height: auto;
  }

  .Footer-middle-top {
    padding: 0px 40px;
    background: #000042;
  }

  .footer-app-store {
    margin: auto;
    display: ruby-text;
  }

  .media-links {
    width: 100% !important;
    height: auto !important;
  }

  .Card-product-type-1-color-box {
    z-index: 2;
  }

  .HeartSaveBtn2 {
    font-size: 21px;
    color: #ff1e1e;
    position: absolute;
    top: 19px;
    right: 35px;
  }

  .style-filter-box {
    margin-top: 10px;
  }

  .buy-with-lens-option-slider-theme3 {
    width: 85%;
    padding: 20px;
  }

  .slider-container100 {
    width: 100%;
  }

  .slider-top-head-nm {
    font-size: 18px;
    margin-left: 15px;
    color: #000042;
  }

  .component-ecom {
    align-items: flex-start;
  }

  .slide-call-head-bar {
    justify-content: center;
  }

  .slide-sub-amount-bar {
    width: 72%;
    font-size: 18px;
    border-radius: 10px;
    padding: 0px 52px;
  }

  .slide-ofr-sectn-btn {
    width: 90%;
    height: auto;
    border-radius: 20px;
    padding: 13px;
  }

  .cart-button {
    justify-content: center;
  }

  .slide-add-cart-btn {
    margin: 15px 0;
  }

  .Buying-For--User {
    flex-direction: column;
    padding-left: 5px;
    align-items: inherit;
  }

  .Buying-For--User {
    font-size: 12;
  }

  .cartpage-pricebox-container-theme3 {
    width: 85%;
    grid-template-columns: repeat(2, 1fr);
    margin: auto;
    justify-items: center;
  }

  .increase_modal {
    width: 90%;
  }

  .lense-view-container-theme3 {
    padding: 5px;
    height: 90px;
    width: 65%;
  }

  .black-codesdf {
    height: 100vh;
  }

  .p-graph-head-title {
    font-size: 23px;
  }

  .Blank-cart-page-btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .lens-option-pararaph-theme3 {
    font-size: 12px;
    margin-left: 7px;
    padding: 5px 10px 5px 0px;
  }

  .lens-option-top-theme3 {
    font-size: 14px;
    margin-left: 7px;
  }

  .Anti-class-add {
    font-size: 17px;
  }

  .slide-mat-desc {
    font-size: 13px;
  }

  .Heading {
    font-size: 14px;
  }

  .SubHeading {
    font-size: 16px;
  }

  .title-name-wl-3 p {
    font-size: 12px;
    margin: 5px;
  }

  .Card-product-type-1-img {
    width: 85%;
    background-size: 90%;
    margin: 10px auto;
  }

  .HeartSaveBtn2 {
    right: 25px;
  }

  .Pincode-width-Lc {
    margin-top: 20px;
  }

  .Check-Btn-Lc {
    margin-top: 30px;
  }

  .sizeVarient div {
    margin: 3px;
  }

  // ********************************** new css himasnhu****************************

  .HeartSaveBtn3 {
    font-size: 21px;
    color: #ff1e1e;
    position: absolute;
    top: 0px;
    right: 10px;
  }

  .ProductDetailPage-image {
    // height: 140px;
    height: 100%;
    margin: 0;
  }

  .account-modal {
    width: 50%;
  }

  .head-body-arrow {
    color: #00bac6;
    padding-left: 0px;
  }

  .ProductDetailPage-image {
    background-size: 75%;
  }

  .lenses-show-multiple {
    gap: 0 !important;
  }

  .pricebox-wishlist-container {
    min-width: 130px;
  }

  .slick-slider-custom button img {
    width: 50px !important;
    height: 50px !important;
  }

  .Arrow-up-down-sidebar {
    width: 10%;
    display: flex;
    justify-content: flex-end;
  }

  .category-side-mobile {
    width: 70%;
    color: rgb(0, 0, 0);
  }

  .dropdown-sidebar-mobile {
    font-size: 12px;
  }

  .Flex-subcat-mobile-Lc {
    display: flex;
    justify-content: flex-start;
    width: 87%;
  }

  .home-card-percentage {
    width: 100%;
  }

  .position-icon-pw {
    top: 52%;
  }

  .navbar-onhover-left-Panel-child img {
    width: 35px;
    margin-top: 10px;
  }

  .home-item-name {
    text-align: center;
    margin-left: 10px;
  }

  .attributesField {
    width: 95%;
    margin: auto;
  }

  .AddCartAlert {
    height: 40px;
    background: black;
    color: white;
    display: flex;
    justify-content: center;
    width: 75%;
    border-radius: 5px;
    // margin-top: 40%;
    font-size: 20px;
    align-items: center;
  }

  .product-detail-button-theme3 {
    width: 95%;
  }

  .CartIcon1 {
    color: #4caf50;
    padding-top: 0px;
    padding-right: 10px;
  }

  .ProductSearch {
    display: flex;
    min-height: 110vh;
    height: max-content;
    margin-top: -4px !important;
    width: 100%;
  }
  .wishlist_background {
    width: 100%;
    height: 130px;
    background-size: 90% !important;
  }

  .printname-title-item {
    font-size: 13px;
    color: #a9a9a9;
    line-height: 20px;
  }

  .Alias-title-item {
    font-size: 13px;
    color: #6c6c6c;
    line-height: 20px;
  }
  .ProductDetailPage-smallimg {
    width: 80%;
    height: 80px;
  }
  .Flex-ImagesTheme_Lc-theme-2 {
    gap: 0px;
    flex-direction: row;
    justify-content: space-around;
  }

  .lense-view-container-theme-2 {
    width: 95%;
    padding: 0;
  }
  .ing-size-xx-xl-theme3 {
    margin-top: 0;
  }

  .modaldisplay-parent {
    padding: 15px;
    width: 80%;
    margin: auto;
  }

  .Flex-ImagesTheme_Lc .slick-slider-custom {
    flex-direction: row;
    width: 100%;
  }
  .Flex-ImagesTheme_Lc .slick-track {
    flex-direction: row;
  }

  .Flex-ImagesTheme_Lc .ProductDetailPage-smallimg-theme {
    width: 60px !important;
    height: 70px !important;
  }
}
