.main-form-appointment {
  display: flex;
  top: 0px;
  /* align-items: center; */
  justify-content: center;
  /* position: absolute; */
  /* width: 100vw; */
  background-color: rgba(0, 0, 0, 0.355);
  /* height: 100vh; */
  padding: 30px;
}

.form-appointment {
  width: 50%;
  min-height: 400px;
  background-color: white;
  border-radius: 20px;
}

.nav-for-form-appointment {
  width: 95%;
  min-height: -1px;
  /* max-height: -moz-fit-content; */
  max-height: fit-content;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.h2-for-heading-appointment {
  font-size: 20px;
  font-family: serif;
  color: #000042;
  padding: 10px 20px;
}

.subheading-appointment {
  font-size: 15px;
  padding-left: 20px;
  font-weight: 200;
}

.btn-cross-appointment {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000042;
  border: none;
  outline: none;
  font-size: 20px;
  cursor: pointer;
  transition: all 200ms;
}

.btn-cross-appointment:hover {
  background-color: #000042;
  color: white;
  transition: all 200ms;
}

.section-for-inputs-appointment {
  width: 100%;
  /* min-height: 500px; */
  /* padding: 20px; */
  /* margin: 20px; */
  /* justify-items: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select-appointment {
  width: 95%;
  height: 30px;
  border-radius: 0.3rem;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  border-color: rgb(230, 230, 236);
  color: #000042;
  text-align: center;
}

.subdiv-for-lr-appointment {
  width: 100%;
  min-height: 300px;
  /* display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 15px; */
}

.left-form-appointment {
  width: 42%;
  min-height: 200px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.right-form-appointment {
  width: 50%;
  min-height: 200px;
  margin-top: 10px;
}

.select-from-appointment {
  width: 97%;
  min-height: 51px;
  height: auto;
  outline: none;
  border: 1px solid rgb(230, 230, 236);
  appearance: none;
  padding-left: 10px;
  color: #000042;
  padding-right: 10px;
  color: rgb(0, 0, 0);
  margin-left: 6px;
}

.option-appointment {
  color: #000042;
}

.checkbox-appointment {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 10px;
  margin-top: 8px;
}

.label-appointment {
  font-size: 15px;
  text-align: center;
}

.check-appointment {
  display: inline;
  width: 20px;
  height: 20px;
}

.p-for-form-appointment {
  width: 100%;
  margin-top: 30px;
  color: #000042;
  height: auto;
  font-size: 17px;
  text-align: center;
}

.btn-proceed-appointment {
  min-width: 180px;
  height: auto;
  font-size: 15px;
  background-color: #000042;
  color: white;
  /* margin: auto; */
}

.div-for-proceed-appointment {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 12px;
}

.appointment-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;
}

.appointment-grid-item {
  margin: 12px;
}

@media (max-width: 1024px) {
  .form-appointment {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .form-appointment {
    width: 90%;
  }

  .appointment-grid-container {
    grid-template-columns: 1fr; /* Single column */
  }
}

@media (max-width: 480px) {
  .h2-for-heading-appointment {
    font-size: 18px;
    padding: 5px 10px;
  }

  .subheading-appointment {
    font-size: 13px;
    padding-left: 10px;
  }

  .btn-cross-appointment {
    width: 25px;
    height: 25px;
    font-size: 18px;
  }

  .btn-proceed-appointment {
    min-width: 150px;
    font-size: 13px;
  }

  .div-for-proceed-appointment {
    width: 60%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 12px;
  }

  .select-appointment,
  .select-from-appointment,
  .appointment-grid-item > input {
    width: 95%;
    margin-left: 6px;
  }

  .appointment-grid-item {
    margin: 10px 0;
  }
}
