/* *{
    padding: 0px;
    margin: 0px;
} */
.main-div-book-appointment {
  /* width: 120vw;
  min-height: 100vh;

  padding-bottom: 20px; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 12px;
  /* gap: 30px;
  padding-right: 150px;
  padding-top: 20px; */
  /* background-color: aqua; */
}

/* .right{
    width: auto;
    border: 1px solid red;
    height: 100vh;
} */
