.footer-lc {
    width: 100%;
    position: relative;
    bottom: 0;
    height: 48vh;
    background-color: rgb(0, 0, 66);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 1px solid rgb(211, 211, 211);
}
.footer-bottom-theme_3 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgb(221, 221, 221);
    font-size: 14px;
    color: white;
    /* margin-top: 10px; */
    height: 60px;
}
.footer-text-link-theme_3 {
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 40px;
    cursor: pointer;
    color: white;
}
.Flex-ImagesTheme_Lc{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  