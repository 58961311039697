.main-div-right-appointment {
  width: 50%;
  height: 90vh;
  padding: 20px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.content-div-appointment {
  width: 100%; 
  max-width: 380px; 
  padding: 20px;
  min-height: 300px;
  border-radius: 20px;
  border: 1px solid rgb(226, 226, 238);
}

.name-tag-appointment {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.h2-for-name-appointment {
  width: 100%;
  font-size: 25px;
  color: #000042;
}

.p-for-name-appointment {
  font-size: 15px;
  width: 100%;
  color: #000042;
}

.span-starr-appointment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;
  gap: 10px;
}

.p-for-rating-appointment {
  width: auto;
  padding: 0px;
}

.div-for-visiontest-appointment {
  width: 100%;
  height: auto;
}

.h2-visiontest-appointment {
  font-size: 17px;
  font-family: serif;
  margin-bottom: 10px;
  color: #000042;
}

.p-visiontest-appointment {
  width: 90%;
  display: flex;
  align-items: center;
  color: #000042;
  justify-content: center;
}

.p-inside-vision-appointment {
  font-size: 14px;
  width: 80%;
  font-family: serif;
}

.div-for-price-appointment {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.span-rupee-appointment {
  font-size: 15px;
  font-weight: 400;
  font-family: serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.price-appointment {
  font-size: 15px;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-button-appointment {
  width: 100%;
  min-height: 30px;
  padding: 10px;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 0.6rem;
  outline: none;
  border: none;
  color: white;
  cursor: pointer;
  background-color: rgb(0, 0, 66);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .main-div-right-appointment {
    width: 80%; /* Example adjustment for smaller screens */
  }

  .content-div-appointment {
    max-width: 100%; /* Adjusted to full width for smaller screens */
  }
}
