.book-appointment-div {
  width: 95%;
  height: 500px;
  margin-left: 2.5%;
  margin-right: 2.5%;
  display: flex;

  top: 82px;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.2rem;
  margin-bottom: 12px;
  background-color: white;

}

.left-img-div {
  width: 57%;
  height: 100%;
  background-position: center;
  object-fit: cover;
  background-size: cover;
  object-position: center;
  padding: 7px;
  border-radius: 18px;
}

.div-for-text-content {
  width: auto;
  display: flex;
  height: 100%;
  align-items: center;
  padding-top: 90px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
}

.h1-for-text-content {
  width: 550px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;

  font-size: 45px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: #000042;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  justify-content: flex-start;
}

.p-for-content {
  width: 550px;
  height: auto;
  padding-left: 20px;
  color: #000042;
  padding-right: 20px;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  font-style: serif;
}

.btn-book {
  width: auto;
  height: auto;
  padding: 20px;
  border-radius: 30px;
  border: 1.5px solid #000042;
  transition: all 200ms;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
}

.btn-book:hover {
  background-color: #000042;
  color: white;
}



@media screen and (max-width: 650px) {
  .main-nav {
    width: 100vw;
    font-size: 15px;
    border: 1px solid red;
    padding: 0px;
  }

  .p-for-home {
    width: fit-content;
    font-size: 15px;

    padding: 0px;
    height: 100%;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.navbar-appointment-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000042;
  padding: 10px 20px;
  color: white;
}

.navbar-logo-appointment-form img {
  height: 50px; 

  filter: invert(1);
}

.navbar-contact-appointment-form span {
  font-size: 16px;

  gap: 12px;
}

.contact-appointment-ins {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
