.search_box_container {
  width: 100%;
  // border: 1px solid black;
  position: absolute;
  background-color: white;
  height: auto;
  overflow: hidden;
  border-radius: 3px;
  //   padding-bottom: 10px;
  border: 1px solid #f7f7f7;
  border-bottom: 0;
  z-index: 12;
}


// ---------------main-div

.div-for-search-input {
    width: 400px;
    height: auto;
    position: relative;
}


// ----search box suggetion
.every-search-suggestion {
  width: 95%;
  overflow: hidden;
  border-bottom: 1px solid #efefef;
  height: auto;
  padding: 0px 10px 0px 10px;
  cursor: pointer;
  color: #2e2e2e;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.every-search-suggestion:hover {
  background-color: #ccc;
}

.search-suggetion-span {
  padding: 8px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
}

.search-suggetion-name {
  width: auto;
  height: auto;
  font-size: 12px;
}

.suggestion-name-img-box {
  display: flex;
  align-items: center;
}